<template>
  <div>
    <div class="bank">
      <HeaderNavigation label="新增" icon="" path="/bank/child/add"  />
      <div class="search-wrap">
        <SearchWrap v-model="searchTotal" @search="resetList"></SearchWrap>
      </div>
      <Card class="card" v-for="(item, index) in list" :key="index">
        <div class="label-list-container">
          <div class="label-value-container" >
            <div class="label">汇款人名称</div>
            <div class="value">{{item.name}}</div>
          </div>
          <div class="label-value-container" >
            <div class="label">银行卡号</div>
            <div class="value">{{item.bankNumber}}</div>
          </div>
          <div class="label-value-container" v-if="item.accountType == '1'">
            <div class="label">身份证号</div>
            <div class="value">{{item.idCard}}</div>
          </div>
          <div class="label-value-container" v-if="item.accountType == '1'">
            <div class="label">与经销商关系</div>
            <div class="value">{{item.relationName}}</div>
          </div>
          <div class="label-value-container menu">
            <div style="display: flex" @click="edit(item)"><img class="edit-icon"  src="../../../assets/images/common/edit.png" /><div style="height: 18px;line-height: 18px;">修改</div></div>
            <div style="display: flex" @click="editList(item.id)"><img class="del-icon" src="../../../assets/images/common/check-icon.png" /><div style="height: 18px;line-height: 18px;">修改记录</div></div>
          </div>
<!--          <div class="label-value-container" >-->
<!--            <div class="label">账号类型</div>-->
<!--            <div class="value">{{item.accountTypeName}}</div>-->
<!--          </div>-->
        </div>
      </Card>

      
      
      <infinite-loading style="margin-top: 20px" ref="infinite" @infinite="infiniteHandler">
        <span slot="no-more">
          暂无更多
        </span>
      </infinite-loading>
    </div>
  </div>
</template>
<script>
import SearchWrap from "@/components/common/search";
import HeaderNavigation from "@/components/header/headerWithNavigation";
import { listChildBank } from "@/api/bank";
import InfiniteLoading from "vue-infinite-loading";

import Card from "@/components/common/card/whiteCard.vue"



export default {
  components: {
    SearchWrap,
    HeaderNavigation,
    InfiniteLoading,
    Card,
  },
  data() {
    return {
      searchTotal: "",
      filterValue: {},
      list: [],
      pageNo: 1,
      pageSize: 20,
      total: 0,
    };
  },
  mounted() {
  },
  activated() {
    this.resetList();
  },
  methods: {
    resetList() {
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = [];
      this.pageNo = 1;
      this.total = 0;
    },
    async infiniteHandler($state) {
      try {
        await this.search();
      } catch (err) {
        //
      }
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },
    async search() {
      const response = await listChildBank({
        searchTotal: this.searchTotal,
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
      });
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    },
   
    
    edit(item) {
      this.$router.push({ path:"/bank/child/add",query:item });
    },
    editList(id) {
      this.$router.push({ path:"/bank/child/record",query:{id: id} });
    },
  },
};
</script>
<style lang="scss" scoped>
.bank {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  .card {
    margin-top: 5px;
    padding: 2px 10px;
    .label-list-container {
      padding-top: 10px;
      padding-bottom: 12px;
    }
    .label-value-container {
      width: 100%;
      height: 20px;
      display: flex;
      //align-items: center;
      font-size: 14px;
      &:not(:last-child){
        margin-bottom: 6px;
      }
      .label {
        color: #818181;
        justify-content: flex-start;
        display: flex;
        align-items: center;
      }
      .value {
        color: #2C2C2C;
        margin-left: 6px;
        flex:1;
        justify-content: flex-end;
        display: flex;
        align-items: center;
      }
      
    }

    .menu {
      padding-top: 4px;
      display: flex;
      justify-content: space-around;
      .edit-icon {
        width: 20px;
        height: 18px;
        padding-right: 5px;
      }

      .del-icon {
        width: 25px;
        height: 18px;
        padding-right: 5px;

      }
    }
    
  }
  
}
</style>
