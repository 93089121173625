import { post } from '@/utils/request';

export const listChildBank = async data => {
  return await post('/appdealer/api/dealer/bank/child/list', data);
}

export const getChildBank = async data => {
  return await post('/appdealer/api/dealer/bank/child/get', data);
}

export const addChildBank = async data => {
  return await post('/appdealer/api/dealer/bank/child/save', data);
}

export const editList = async data => {
  return await post('/appdealer/api/dealer/bank/child/editList', data);
}

export const ocrIdCard = async data => {
  return await post('/appdealer/api/dealer/bank/child/ocrIdCard', data);
}

export const ocrBankAccount = async data => {
  return await post('/appdealer/api/dealer/bank/child/ocrBankAccount', data);
}

export const fetchBankMoneyList = async data => {
  return await post('/appdealer/api/dealer/bank/child/bankMoneyList', data)
}

export const bankMoneyRead = async data => {
  return await post('/appdealer/api/dealer/bank/child/bankMoneyRead', data)
}