<template>
  <div class="search-form">
    <Field
      class="field"
      placeholder="请输入"
      size="small"
      :value="value"
      @input="handleChange"
    ></Field>
    <Button class="search-btn" size="small" @click="handleSearch">
      <img :src="SearchIcon" alt="" />
      <span>搜索</span>
    </Button>
  </div>
</template>
<script>
import { Field, Button } from "vant";
import SearchIcon from "@/assets/images/common/search-icon.svg";
export default {
  components: {
    Field,
    Button,
  },
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return { SearchIcon };
  },
  methods: {
    handleSearch() {
      this.$emit("search");
    },
    handleChange(val) {
      this.$emit("input", val);
    },
  },
}
</script>
<style lang="scss" scoped>
.search-form {
  width: 100%;
  display: flex;
  height: 25px;
  .field {
    padding: 3px 12px;
    background: #f4f4f5;
    opacity: 1;
    border-radius: 5px 0 0 5px;
    ::v-deep .van-field__body {
      height: 100%;
      .van-field__control {
        height: 100%;
      }
    }
  }
  .search-btn {
    height: 100%;
    font-size: 12px;
    color: #ffffff;
    background: #cd001f;
    border-radius: 0px 5px 5px 0px;
    flex-shrink: 0;
    ::v-deep .van-button__text {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: auto;
        width: 12px;
      }
      span {
        transform: translateY(0.5px);
      }
    }
  }
}
</style>
