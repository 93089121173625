<template>
  <div>
    <div class="shidanli-business-knowledge">
      <HeaderNavigation label="新增地址" icon="" path="/address/add" :tranType="transportWayValue" />
      <div class="search-wrap">
        <div class="sort-wrap" @click="getTransportWayPicker">
          <img src="../../../assets/images/common/arrow-down.png" alt="" />
          {{transportWay}}
        </div>
        <Popup v-model="showTransportWayPicker" position="bottom">
          <Picker
              title="选择发货公司"
              show-toolbar
              :columns="transportWayColumns"
              @confirm="onTransportWayConfirm"
              @cancel="onTransportWayCancel"
          />
        </Popup>
        <SearchWrap v-model="searchTotal" @search="resetList"></SearchWrap>
      </div>
      <div class="list-wrap" 
           v-show="transportWayValue === '20' || transportWayValue === '30'" 
           v-for="(item, index) in list" :key="index">
        <div class="list-wrap-left">
          <div class="date-name-container">
            <div class="name">{{item.tranConsignee}}</div>
            <div class="phone">{{item.tranTel}}</div>
          </div>
          <div class="summary-container">{{item.tranAddress}}</div>
        </div>
        <div class="list-wrap-right">
          <img class="edit-icon" src="../../../assets/images/common/edit.png" @click="edit(item)" />
          <img class="del-icon" src="../../../assets/images/common/del.png"  @click="del(item.rowId)" />
        </div>
      </div>

      <div class="card-wrap" v-show="transportWayValue === '10'"
           v-for="(item2, index2) in list" :key="'2-'+index2">
        <div class="content-item flex-50">
          <span class="title">{{item2.tranAddress}}</span>
        </div>
        <div class="content-item flex-50">
          <img class="edit-icon"  src="../../../assets/images/common/edit.png"  @click="edit(item2)" />
          <img class="del-icon" src="../../../assets/images/common/del.png" @click="del(item2.rowId)" />
        </div>
        <div class="content-item">
          <span class="label">收货人:</span>
          <span class="label">{{item2.tranConsignee}}</span>
          <span class="label" style="padding-left: 20px;padding-top: 1px;">{{item2.tranTel}}</span>
        </div>
        <div class="content-item flex-50">
          <span class="label">到局:</span>
          <span class="label">{{item2.tranAdvent}}</span>
        </div>
        <div class="content-item flex-50">
          <span class="label">到站:</span>
          <span class="label">{{item2.tranArrival}}</span>
        </div>
        <div class="content-item flex-50">
          <span class="label">小票收件人:</span>
          <span class="label">{{item2.tranBillConsignee}}</span>
        </div>
        <div class="content-item flex-50">
          <span class="label">邮编:</span>
          <span class="label">{{item2.code}}</span>
        </div>
        <div class="content-item">
          <span class="label">小票邮寄地址:</span>
          <span class="label" style="padding-top: 1px;">{{item2.tranBillAddress}}</span>
        </div>
      </div>
      
      
      <infinite-loading style="margin-top: 20px" ref="infinite" @infinite="infiniteHandler">
        <span slot="no-more">
          暂无更多
        </span>
      </infinite-loading>
    </div>
  </div>
</template>
<script>
import SearchWrap from "@/components/common/search";
import HeaderNavigation from "@/components/header/headerWithNavigation";
import { fetchReceiveAddressList } from "@/api/select";
import InfiniteLoading from "vue-infinite-loading";
import {fetchDictList} from "@/api/order";
import { Picker,Popup } from 'vant';
import { Dialog  } from 'vant';
import {getDealerId} from '@/utils/user';
import {delAddress} from "@/api/address";


export default {
  components: {
    SearchWrap,
    HeaderNavigation,
    InfiniteLoading,
    Picker,
    Popup
  },
  data() {
    return {
      searchTotal: "",
      filterValue: {},
      list: [],
      pageNo: 1,
      pageSize: 20,
      total: 0,
      transportWay: '汽运',
      transportWayValue: '20',
      transportWayColumns: [],
      showTransportWayPicker: false,
    };
  },
  mounted() {
    this.fetchTransportWay();
  },
  activated() {
    this.resetList();
  },
  methods: {
    resetList() {
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = [];
      this.pageNo = 1;
      this.total = 0;
    },
    async infiniteHandler($state) {
      try {
        await this.search();
      } catch (err) {
        //
      }
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },
    async search() {
      const response = await fetchReceiveAddressList({
        "tranType": this.transportWayValue, // 分类
        "tranConsignee": this.searchTotal, // 分类
        "dealerId": getDealerId(), // 分类
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
      });
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    },
   
    async fetchTransportWay() {
      let response = await fetchDictList({
        "type": 'eb_tran_mode'
      })
      response.data.forEach(item => {
        this.transportWayColumns.push({
          "value":item.dictValue,
          "text":item.dictLabel
        })
      });
    },
    getTransportWayPicker() {
      this.showTransportWayPicker = true;
    },
    onTransportWayCancel() {
      this.showTransportWayPicker = false;
    },
    onTransportWayConfirm(value) {
      this.showTransportWayPicker = false;
      this.transportWay = value.text;
      this.transportWayValue = value.value;
      this.resetList();
    },
    edit(item) {
      this.$router.push({ path:"/address/add",query:item });
    },
    async del(rowId) {
      await Dialog.confirm({
        message: '确定要删除该记录？',
      }).then(() => {
        delAddress({rowId:rowId});
      }).catch(() => {
        // on cancel
      });
      this.$toast('删除成功');
      this.resetList();
    }
  },
};
</script>
<style lang="scss" scoped>
.shidanli-business-knowledge {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  .search-wrap {
    padding: 10px 24px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sort-wrap {
      margin-right: 20px;
      font-size: 14px;
      color: #2c2c2c;
      flex-shrink: 0;
      img {
        width: 16px;
        height: auto;
        transform: translateY(2px);
      }
    }
  }
  .list-wrap {
    width: 95%;
    min-height: 70px;
    box-sizing: border-box;
    padding: 12px 12px 14px;
    background: white;
    margin: 5px auto 0 auto;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    opacity: 1;
    border-radius: 10px;
    .list-wrap-left{
      width: 80%;
      float: left;
      .date-name-container {
        text-align: left;
        display: flex;
        align-items: center;
        color: #2C2C2C;
        .name {
          margin-left: 13px;
          font-size: 16px;
          font-weight: 500;
        }
        .phone {
          margin-left: 15px;
          margin-top: 2px;
          font-size: 12px;
          font-weight: 500;
        }
      }
      .summary-container {
        margin-top: 6px;
        margin-left: 14px;
        height: 17px;
        line-height: 17px;
        font-size: 12px;
        color: #818181;
        display: flex;
       
      }
    }
    .list-wrap-right{
      width: 20%;
      float: left;
      margin-top: 10px;
      .edit-icon {
        width: 20px;
        height: 20px;
        padding-right: 15px;
      }
      .del-icon {
        width: 20px;
        height: 20px;
      }
    }
  }

  .card-wrap {
    position: relative;
    background: #ffffff;
    width: 88%;
    margin: 5px auto 0 auto;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    opacity: 1;
    border-radius: 10px;
    padding: 12px;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    & > div {
      flex: 0 0 100%;
    }
    .content-item {
      display: flex;
      padding-left: 10px;
      opacity: 1;
      margin-bottom: 2px;
      .title {
        color: #4c4c4c;
        height: 18px;
        font-size: 16px;
        margin-bottom: 8px;
      }
      &.flex-50 {
        flex: 0 0 46%;
      }
      .label {
        color: #818181;
        font-size: 14px;
        margin-right: 4px;
        flex-shrink: 0;
      }
      .edit-icon {
        width: 18px;
        height: 18px;
        margin:auto 0 auto 0;
        padding-left: 65px;
      }
      .del-icon {
        width: 18px;
        height: 18px;
        margin:auto 0 auto 0;
        padding-left: 20px;
      }
    }
    
  }
  
}
</style>
