import { post } from '@/utils/request';

export const addAddress = async data => {
  return await post('/appdealer/api/eb/order/saveaddress', data);
}

export const editAddress = async data => {
  return await post('/appdealer/api/eb/order/updateaddress', data);
}

export const delAddress = async data => {
  return await post('/appdealer/api/eb/order/deleteaddress', data);
}